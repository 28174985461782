import React, { useState, useEffect } from 'react'
import { Accordion, AccruentImg } from './commonComponents';
import getTitleId from "./helpers/getTitleId";
import getImageObject from '../../helpers/getImageObject';
import "src/scss/components/_qaListSection.scss";

/**
 *
 * @param {Objects} contentEntity - entity of QA List: {fieldQaSectionFormat,fieldBackgroundImage, ...}
 * @param {Array of Objects} data - list of QA Pair: [{ title: String, content: String, id: String/Number }]
 */

const QAList = ({ contentEntity, data }) => {
    if (!Array.isArray(data)) return <></>
    const [activeQA, setActiveQA] = useState(data[0]?.id)
    const isColoredQaSection = contentEntity?.fieldQaSectionFormat === "qa_content_with_background_color_with_background_image"
    let leftColumnCount = Math.round(data.length / 2);
    let leftData = data
    let rightData = []

    if (leftColumnCount && isColoredQaSection) {
        leftData = data.slice(0, leftColumnCount)
        rightData = data.slice(leftColumnCount, data.length)
    }
    const bg = contentEntity?.fieldBackgroundImage?.localFile
    const bgObject = getImageObject(contentEntity?.relationships?.fieldBackgroundImage?.relationships?.fieldImage, contentEntity?.relationships?.fieldBackgroundImage?.fieldImage)
    const handleClick = (item) => {
        if (item?.id === activeQA) {
            setActiveQA(0)
        } else {
            setActiveQA(item?.id)
        }
    }



    return (
        <div className={`qa-list-container`} >
            <div
                className={`${isColoredQaSection ? "tw-bg-accruent_sapphire" : ""}`}
                style={isColoredQaSection ? { marginLeft: 'calc(100% - 100vw)', marginRight: 'calc(100% - 100vw)', paddingLeft: 'calc(100vw - 100%)', paddingRight: 'calc(100vw - 100%)' } : {}}
            >
                <div
                    className={`tw-break-words qa-list ${contentEntity?.fieldQaSectionFormat}`}
                    component="QaList"
                    nid={contentEntity.nid}
                >
                    {isColoredQaSection && bgObject && <div className='is-hidden-mobile qa-bg'> <AccruentImg file={bgObject} /> </div>}
                    <div className="tw-grid tw-grid-cols-1">
                        <div className="" id={getTitleId(contentEntity.title)}>
                            {contentEntity?.fieldHideTitle &&
                                contentEntity?.fieldShowTitleFullWidth ?
                                <h2 className={`${isColoredQaSection ? 'tw-text-accruent_white tw-mb-8' : ''} tw-mobileXxxlFont md:tw-text-xxxlFont tw-break-normal tw-mb-6`}>
                                    {contentEntity.title}
                                </h2>
                                :
                                <h3 className={`${isColoredQaSection ? 'tw-text-accruent_white tw-mb-8' : ''} tw-mobileXxxlFont md:tw-text-xxxlFont tw-break-normal tw-mb-6 !tw-w-full`}>
                                    {contentEntity.title}
                                </h3>
                            }

                            {!isColoredQaSection ? (
                                <Accordion
                                    data={data}
                                    id={`id${contentEntity.nid}`}
                                    isQa={true}
                                />
                            ) : (
                                <div className='qa-list-container'>
                                    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-6">
                                        <div class="">
                                            <Accordion
                                                data={leftData}
                                                id={`id${contentEntity.nid}left`}
                                                isColoredQa
                                                activeItemId={activeQA}
                                                handleClick={handleClick}
                                            />
                                        </div>
                                        {!!rightData?.length && <div class="">
                                            <Accordion
                                                data={rightData}
                                                id={`id${contentEntity.nid}right`}
                                                isColoredQa
                                                activeItemId={activeQA}
                                                handleClick={handleClick}
                                            />
                                        </div>
                                        }
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QAList